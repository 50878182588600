<template>
  <page>
    <template slot="headerInfo">
      <el-button
        v-auth="'middle-purchase:downloadMainStoreList'"
        :disabled="!tableData.length"
        @click="exportOrder"
        type="primary"
      >
        导出订单信息
      </el-button>
    </template>
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="订单编号" prop="purchaseNo">
          <el-input
            v-model="params.purchaseNo"
            clearable
            placeholder="订单编号"
          />
        </el-form-item>

        <el-form-item label="下单时间" prop="date">
          <el-date-picker
            v-model="params.date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="发货状态" prop="status">
          <el-select v-model="params.status" clearable>
            <el-option
              v-for="item in orderStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="支付状态" prop="payStatus">
          <el-select v-model="params.payStatus" clearable>
            <el-option
              v-for="item in payStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商户名称" prop="storesName">
          <el-input
            v-model="params.storesName"
            clearable
            placeholder="商户名称"
          />
        </el-form-item>

        <el-form-item label="发货车辆" prop="licensePlate">
          <el-select v-model="params.licensePlate" clearable>
            <el-option
              v-for="item in carList"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="省" prop="provinceId">
          <el-select v-model="params.provinceId" clearable @change="getCity">
            <el-option
              v-for="item in provinceList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市" prop="cityId">
          <el-select v-model="params.cityId" clearable @change="getRegion">
            <el-option
              v-for="item in cityList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区/县" prop="regionId">
          <el-select v-model="params.regionId" clearable>
            <el-option
              v-for="item in regionList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        v-loading="loading"
        height="100%"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          show-overflow-tooltip
          min-width="100"
          prop="purchaseNo"
          label="订单编号"
        />
        <el-table-column
          class-name="tableGoodsListWrap"
          min-width="150"
          label="商品清单"
        >
          <template slot-scope="{ row }">
            <div class="addressDetail">
              <p
                :title="`${item.goodsName}*${item.goodsNum}`"
                v-for="(item, index) in row.isAll
                  ? row.goodsAllList
                  : row.goodsPartList"
                :key="index"
              >
                <span class="maxText"
                  >{{ item.goodsName }}*{{ item.goodsNum }}</span
                >
              </p>

              <el-button
                v-if="row.goodsAllList.length > 3"
                @click="row.isAll = !row.isAll"
                type="text"
                :icon="!row.isAll ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                >{{ !row.isAll ? '展开' : '收起' }}</el-button
              >
            </div>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="payPrice"
          width="120"
          label="订单金额(元)"
        />

        <el-table-column
          show-overflow-tooltip
          prop="storesName"
          label="下单商户"
        />

        <el-table-column
          show-overflow-tooltip=""
          min-width="200"
          label="收货信息"
        >
          <template slot-scope="{ row }">
            <div class="addressDetail">
              <p>
                <span class="label">收货人:</span>
                <span class="maxText">{{ row.cargoReceiver }}</span>
              </p>
              <p>
                <span class="label">联系方式:</span>
                <span class="maxText">{{ row.phone || '-' }}</span>
              </p>
              <p>
                <span class="label">收货地址:</span>
                <span class="maxText">{{ row.storeAddress || '-' }}</span>
              </p>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip=""
          label="下单时间"
          prop="createTimeStr"
          width="120"
        />

        <el-table-column
          show-overflow-tooltip
          prop="payStatus"
          label="发货状态"
        >
          <template slot-scope="{ row }">
            <span>{{
              row.status == 1
                ? '未下单'
                : row.status == 2
                ? '待配送'
                : row.status == 3
                ? '配送中'
                : '已完结'
            }}</span>
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip label="支付状态">
          <template slot-scope="{ row }">
            <span>{{
              row.payStatus == 1
                ? '已付款'
                : row.payStatus == 2
                ? '未付款'
                : '-'
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip=""
          label="发货时间"
          prop="arrivalTimeStr"
          width="120"
        />

        <el-table-column label="操作" fixed="right">
          <template slot-scope="{ row }">
            <el-button
              v-auth="'middle-purchase:detail'"
              @click="getDetail(row)"
              type="text"
              size="mini"
            >
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <DetailModal
      v-if="purchaseDetailVisible"
      :visible.sync="purchaseDetailVisible"
      :currentItem="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import DetailModal from './components/DetailModal.vue'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      currentTitle: '确认收款',
      currentItem: null,
      currentNo: null,
      currentPayPrice: null,
      loading: false,
      onlyInfo: false,
      storesList: [],
      purchaseDetailVisible: false,
      sendOrderData: [],
      tableData: [],
      orderStatusList: [
        {
          label: '未下单',
          value: 1
        },
        {
          label: '待配送',
          value: 2
        },
        {
          label: '配送中',
          value: 3
        },
        {
          label: '已完结',
          value: 4
        }
      ],
      payStatusList: [
        {
          label: '已付款',
          value: '1'
        },
        {
          label: '未付款',
          value: '2'
        }
      ],
      multipleSelection: [],
      carList: [],
      provinceList: [],
      cityList: [],
      regionList: [],
      params: {
        limit: 20,
        page: 1,
        date: []
      },
      totalCount: 0
    }
  },
  components: {
    DetailModal
  },
  created() {
    this.getProvince()
    this.getCarList()
  },
  methods: {
    async getProvince() {
      try {
        const res = await this.$api.common.getProvince()

        this.provinceList = (res.list || []).map(item => ({
          value: item.id,
          label: item.name
        }))
      } catch (e) {
        console.log(e)
      }
    },
    async getCity(val) {
      if (!val) return
      try {
        const res = await this.$api.common.getCity(val)

        this.cityList = (res.list || []).map(item => ({
          value: item.id,
          label: item.name
        }))
      } catch (e) {
        console.log(e)
      }
    },
    async getRegion(val) {
      if (!val) return
      try {
        const res = await this.$api.common.getRegion(val)

        this.regionList = (res.list || []).map(item => ({
          value: item.id,
          label: item.name
        }))
      } catch (e) {
        console.log(e)
      }
    },
    async getCarList() {
      try {
        const res = await this.$api.carManage.getCarAllList()

        this.carList = res.vehicles.map(item => ({
          label: item.licensePlate,
          value: item.id
        }))
      } catch (e) {
        console.log(e)
      }
    },
    getDetail(row) {
      this.currentItem = row
      this.purchaseDetailVisible = true
    },
    collectRecord(purchaseNo) {
      this.currentNo = purchaseNo
      this.currentTitle = '收款记录'
      this.collectVisible = true
    },
    payRecord(purchaseNo) {
      this.currentNo = purchaseNo
      this.currentTitle = '付款记录'
      this.collectVisible = true
    },
    confirmCollect({ purchaseNo, payPrice }) {
      this.currentNo = purchaseNo
      this.currentPayPrice = payPrice
      this.currentTitle = '确认收款'
      this.collectVisible = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => _.purchaseNo)
    },

    async getData(query) {
      try {
        this.loading = true

        let params = { ...this.params }

        if (query) {
          params.limit = 20
          params.page = 1
        }

        if (params.date && params.date.length) {
          params.beginDate = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD HH:mm:ss'
          )
          params.endDate = this.$utils.dateFormat(
            params.date[1],
            'YYYY-MM-DD HH:mm:ss'
          )
        } else {
          params.beginDate = undefined
          params.endDate = undefined
        }

        const { date, ...sendData } = params

        const res = await this.$api.storeProcurementOrders.getList(sendData)
        this.tableData = ((res.page && res.page.list) || []).map(item => {
          item.amount = this.$utils.amount(item.amount)

          item.createTimeStr = this.$utils.dateFormat(
            item.createTime,
            'YYYY-MM-DD HH:mm:ss'
          )

          item.arrivalTimeStr = this.$utils.dateFormat(
            item.arrivalTime,
            'YYYY-MM-DD HH:mm:ss'
          )

          item.payTimeStr = this.$utils.dateFormat(
            item.payTime,
            'YYYY-MM-DD HH:mm:ss'
          )

          const goodsList = item.goodsVoList

          item.isAll = false
          item.goodsAllList = [...goodsList]
          item.goodsPartList = [...goodsList.slice(0, 3)]

          item.storeAddress =
            (item.provinceName || '') +
            (item.cityName || '') +
            (item.regionName || '') +
            (item.address || '')

          return item
        })

        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    sendHandler(row, onlyInfo) {
      this.currentItem = row
      this.sendVisible = true
      this.onlyInfo = onlyInfo
    },

    confirmTrigger(row) {
      this.currentItem = row
      this.confirmVisible = true
    },

    async exportOrder() {
      try {
        this.loading = true

        const params = { ...this.params }

        if (params.date && params.date.length) {
          params.beginDate = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD'
          )
          params.endDate = this.$utils.dateFormat(params.date[1], 'YYYY-MM-DD')
        } else {
          params.beginDate = undefined
          params.endDate = undefined
        }

        const { date, ...sendData } = params

        sendData.purchaseList = this.multipleSelection

        await this.$api.storeProcurementOrders.downloadMainStoreList(sendData)
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async mergeOrder() {
      this.sendOrderData = this.multipleSelection
      this.logisticsVisible = true
    }
  }
}
</script>

<style scoped lang="scss"></style>
